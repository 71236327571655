<template>
    <Header :title="role.name" :backLinkText="$tc('back_to_role', 2)" :backLinkURL="{ name: 'roles' }" />

    <section class="mt-10">
        <TabMenu :links="menuLinks" />
    </section>

    <section class="mt-10">
        <router-view :role="role" />
    </section>
</template>

<script>
    import TabMenu from '@/components/general/TabMenu';

    export default {
        components: {
            TabMenu,
        },
        data() {
            return {
                menuLinks: [
                    { to: { name: 'roleSettings' }, display_name: this.$tc('setting', 2) },
                    { to: { name: 'rolePermissions' }, display_name: this.$tc('permission', 2) },
                    { to: { name: 'roleUsers' }, display_name: this.$tc('user', 2) },
                ],
            };
        },
        created() {
            this.$store.dispatch('roles/fetchRole', this.$route.params.role_id);
        },
        computed: {
            role() {
                return this.$store.getters['roles/role'];
            },
        },
    };
</script>
